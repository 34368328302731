<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="cleaningwaterbased()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/materials/cleaninwater.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Cleaning Water Based
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="cleaningagentsolvent()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/materials/agent.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Cleaning Agent Solvent Based
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="solderconsumable()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/materials/conso.webp"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Solder Consumables 
              </h4>
            </div>
          </div>
          </a>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="otherproductcleaning()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/materials/DI-water.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Other Product
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/materials/spray_solution">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/materials/Cleaner-1536x1536.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Spray Solution
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        </div>
      </div>
        <Footer />
    </div>
</template>
<script>

import Header from '../../components/Header.vue';

import Footer from '../../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
  
    Footer
    
   
  },
   methods: {
     cleaningwaterbased(){
      window.location.href = "https://www.dct.cleaning/decotron-water-based-cleaning-agents/";

    },
    cleaningagentsolvent(){
      window.location.href = "https://www.dct.cleaning/proton-solvent-based-cleaning-agents/";
    },

otherproductcleaning(){
  window.location.href = "https://www.dct.cleaning/other-products/";
    },
    
solderconsumable(){
  window.location.href = "https://interflux.com/en";
    },
  },
  
};
</script>